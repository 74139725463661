
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner
    border-color: $colors-blue-400

.ant
    /*&-btn
     padding: 0 24px
     height: 36px
     border-radius: 6px
     line-height: 34px
        /*&-ghost
         border: none
         &:hover
         color: $secondary */
    &icon
        &-close
            path
                stroke: currentColor
                stroke-width: 2%
    &-btn
        &-primary
            &:hover
                background: $colors-blue-500
        &-secondary
            color: $colors-blue-400
            border-color: $colors-blue-50 !important
            font-weight: 600
            &.bold
                border-color: $colors-blue-400 !important
            &:hover
                background: $colors-blue-50
        &-icon:not(.icon)
            display: inline-flex
            align-items: center
            padding-top: rem(6) !important
            line-height: 1.4286

            &.disabled
                opacity: 0.5
            .anticon.custom, .anticon.ant-pixel-icon
                font-size: 1.25rem
                line-height: 1.25rem
            &:not(.ant-btn)
                color: $colors-gray-600
                padding: rem(4)
                border-radius: rem(4)
                &:hover
                    background: $colors-gray-50
        &-ghost
            padding: 0 1rem
            color: $colors-gray-600
            &:hover
                color: $colors-gray-600
                background: $colors-gray-50
        &-warning
            background: #E9D08C
            font-weight: 600
            border-color: #E9D08C !important
            &:hover
                background: darken(#E9D08C,10%)
                color: #000

        &.ant-btn-loading
            pointer-events: none
            &::before
                display: none
        &-circle
            min-width: 2rem
            line-height: rem(18)
            height: 2rem
        &-transparent
            background: none
        &-dotted
            border: 1px dashed $colors-gray-400
            color: $colors-gray-600
        &-loading
            &.ant-btn-loading-grey
                border-color: $colors-gray-50
                background: $colors-gray-50
                color: $colors-gray-400
    &-menu
        background-color: transparent
        &-item
            align-items: center
            display: flex
        &-inline,
        &-vertical,
        &-vertical-left
            border: none
        a:hover
            text-decoration: none
        &.ant-menu-inline-collapsed
            &> .ant-menu-item
                .ant-menu-title-content
                    display: inline-block
                    opacity: 0
    &-card
        border-radius: 6px
        border-color: #D0D6DD
    &icon.custom,&icon.ant-pixel-icon
        font-size: 1.5rem
    &-timeline,&-table
        line-height: rem(20)
    &-timeline
        position: relative
        top: 0.438rem
        &-item
            &:last-child
                .ant-timeline-item-tail
                    display: none
            &-content
                margin-left: 1rem
                top: -0.3rem
    &-table
        color: #232933
        &-thead
            &> tr > th
                background: $colors-gray-25
                font-weight: 600
                &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before
                    display: none
            th.ant-table-column-has-sorters
                &:hover
                    background: none
        &-ping-right
            .ant-table-cell-fix-right
                &-first,&-last
                    background: #fff
                    &::after
                        box-shadow: none
                        border-right: 3px solid $colors-gray-100
        &-column
            &-sorter
                color: $colors-gray-600
                margin-left: rem(10)
                margin-bottom: -1px
            &-sorters
                justify-content: left

            &-title
                flex: initial
        &-cell
            &.text-right
                .ant-table-column-sorters
                    justify-content: right
        &-thead > tr > th,
        &-tbody > tr > td,
        tfoot > tr > th,
        tfoot > tr > td
            padding: rem(14) 0.5rem
        &-tbody > tr > td
            padding: rem(8) 0.5rem
        &-tbody > tr > td,
        &-thead > tr > th
            border: none
            box-shadow: inset 0px -1px 0px #D0D6DD
        &-tbody > tr > td
            vertical-align: top
        &-wrapper.val-center
            .ant-table
                .ant-table-tbody > tr > td
                    vertical-align: middle
        &-layout-fixed
            overflow: auto
    &-tooltip
        &-inner
            background: rgba(22, 26, 32, 0.8)
            box-shadow: 0 rem(2) rem(4) rgba(0, 0, 0, 0.14), 0 0 rem(2) rgba(0, 0, 0, 0.12)
            border-radius: rem(4)
            font-size: rem(12)
            padding: 0.25rem 0.5rem
            line-height: 2
            //min-height: 0
        &-arrow
            display: none
        .ballon
            display: none
        a
            text-decoration: none

    &-select
        &-dropdown
            border: 1px solid #8B95A5
            box-shadow: 0 rem(10) rem(15) rem(-3) rgba(0, 0, 0, 0.1), 0 rem(4) rem(6) rem(-2) rgba(0, 0, 0, 0.05)
            border-radius: rem(6)
            &.no-nested
                .ant-select-item-option-grouped
                    padding-left: rem(12)
        &-item-option-content
            color: #232933
        &:not(.ant-select-customize-input)
            .ant-select-selector
                border-radius: rem(6)
            /*&:not(.ant-select-multiple)
             .ant-select-selector
             height: rem(36)
        &-arrow
            color: $colors-gray-600
        &-single
            .ant-select-selector
                .ant-select-selection-item,
                .ant-select-selection-placeholder
                    line-height: rem(34)
                    padding-right: rem(12)
                    overflow: hidden
                    text-overflow: ellipsis
                .ant-select-selection-item
                    &> *
                        overflow: hidden
                        text-overflow: ellipsis
        &-item-option-disabled
            opacity: 0.5
        &-selection-overflow-item:not(:first-child)
            //margin-left: 0.25rem
        &-multiple

            .ant-select-clear
                right: rem(32)
                //opacity: 1
                font-size: 1rem
                top: calc(50% - 0.5rem)
                height: 1rem
                width: 1rem
                margin: 0
            .ant-select-selector
                padding: 0.25rem rem(56) 0.25rem 0.25rem !important
                .ant-select-selection-overflow
                    gap: 0.25rem
            .ant-select-selection-item
                line-height: 1.25rem
                padding: 0.25rem 0.5rem
                height: 1.75rem
                border: none
                background: #EDF0F2
                border-radius: 9999px
                margin: 0
                &-remove > .anticon
                    font-size: 0.875rem
        &-dropdown-long
            width: rem(312) !important
        &-dropdown-medium
            width: rem(160) !important
        &-dropdown-multiple
            .ant-select-item-option
                &:before
                    content: ""
                    background: #FFFFFF
                    border: 1.5px solid #D0D6DD
                    border-radius: 4px
                    width: 1rem
                    height: 1rem
                    position: absolute
                    top: calc(50% - 0.5rem)
                    left: rem(12)
                    transition: 0.5s ease

                &::after
                    position: absolute
                    display: table
                    border: 0.125rem solid #fff
                    border-top: 0
                    border-left: 0
                    transform: rotate(45deg) scale(1) translate(-50%, -50%)
                    opacity: 1
                    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s
                    content: " "
                    top: 50%
                    left: 1rem
                    width: 0.357rem
                    height: 0.571rem
                /*&:after
                 content: ""
                 background: blue
                 position: absolute
                 width: 1rem
                 height: 1rem
                 top: calc(50% - 0.5rem)
                 left: 12px
                 border-radius: 4px*/

                &-selected:not(.ant-select-item-option-disabled)
                    color: inherit
                    font-weight: inherit
                    background: none
                    &::before
                        background: $colors-blue-400
                    .ant-select-item-option-state
                        display: none

            .ant-select-item.ant-select-item-option .ant-select-item-option-content
                margin-left: rem(24)
                //margin-left: rem(12)
            .ant-select-item-option-grouped
                padding-left: rem(12)
        &-arrow .anticon-search
            font-size: 1rem
            margin-top: -0.125rem
        &-dropdown
            padding: rem(8) 0
            

    &-input
        //line-height: rem(26)
        border-radius: rem(6)
        /*&-affix-wrapper
         border-radius: 6px */
        &-show-count-suffix
            position: absolute
            right: 0
            top: -85%
    &-col-auto
        flex: 1 1
    &-dropdown
        background: #fff
        a
            text-decoration: none
    &-checkbox
        &-inner
            border-radius: 4px
        &-indeterminate
            .ant-checkbox-inner
                background: $colors-blue-400
                border-color: $colors-blue-400
                &::after
                    background-color: #fff
                    height: 2px
        &-disabled.ant-checkbox-checked
            .ant-checkbox-inner
                background-color: #8B95A5
                border-color: #8B95A5 !important
                &::after
                    border-color: #fff !important
    &-form
        &-item
            margin-bottom: rem(20)
            .ant
                &-picker,&-input-number,&-input-number-affix-wrapper
                    width: 100%
            &.mb-0
                margin-bottom: 0
            &-label
                &> label
                    font-weight: 600
                    color: #232933
                    &.ant-form-item-required:not(.ant-form-item-required-mark-optional)
                        &::before
                            display: none
                    span
                        font-weight: 400
        &-vertical .ant-form-item-label,
        .ant-col-24.ant-form-item-label,
        .ant-col-xl-24.ant-form-item-label
            padding: 0 0 0.25rem
        &.with-asterix
            .ant-form-item
                &-label
                    &> label
                        &.ant-form-item-required:not(.ant-form-item-required-mark-optional)
                            &::after
                                display: inline-block
                                margin-right: rem(4)
                                color: #ff4d4f
                                font-size: rem(14)
                                line-height: 1
                                content: '*'
    &-modal
        border: 1px solid $colors-gray-400
        //box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10)
        padding: 0
        border-radius: rem(6)
        overflow: hidden
        &-content
            border-radius: 0
        &-header
            background: #F1F5F9
            border-bottom: 1px solid $colors-gray-100
        &-body
            padding: rem(16)
        &-footer
            border: none
        &-mask
            background-color: rgba(0, 0, 0, 0.15)
        &-mask,&-wrap
            z-index: 1002
        &-confirm
            .ant-modal-body
                padding: 0
            .ant-modal-close-x
                height: rem(48)
                line-height: rem(48)
            &-body
                &> .anticon
                    display: none
                    &+ .ant-modal-confirm-title + .ant-modal-confirm-content
                        margin: 0
                        padding: 1rem

            &-title
                padding: rem(12) 1rem
                background: #F2F4F7
                border-bottom: 1px solid #D0D6DD

            .ant-modal-content
                background: #FFFFFF
                //border: 1px solid #8B95A5
                //box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)
                border-radius: 6px
                overflow: hidden

            .ant-modal-confirm-btns
                margin: 0
                padding: 1.25rem 1rem
    &-result
        &-image
            width: 251px
            height: 294px
        &-icon
            img
                display: inline-block
        &-title
            font-weight: 600
            font-size: 1rem
            line-height: 1.5
            margin: 0

    &-alert
        border: none
        border-radius: 6px
        padding: rem(10) 0.75rem
        &-warning
            background: #FBF3DD
        &.ant-alert-error
            background: #FDECEE
        &-btn
            padding-left: rem(12)
            padding-right: rem(12)
        &-icon
            margin-right: 0.75rem
        &-close-icon
            color: $colors-gray-600
            &:hover
                color: $colors-dark

    &-drawer
        z-index: 1002
        &-content-wrapper
            //padding-top: 3.5rem
        &-header
            background: #F2F4F7
            font-weight: 600
            font-size: 16px
            line-height: 24px
            font-feature-settings: 'tnum' on, 'lnum' on
            color: #232933
            border-bottom: 1px solid $colors-gray-100
            &-title
                flex-direction: row-reverse
        &-close
            margin: 0
            padding: 0
        &-body
            padding: rem(16) rem(16) rem(60)
            position: relative
        &-footer
            border: none
        &-mask
            //background: none
            background: rgba(35, 41, 51, 0.80)
        &-right.ant-drawer-open
            .ant-drawer-content-wrapper
                box-shadow: 0px 9px 22px 8px rgb(0 0 0 / 15%), 0px 6px 16px rgb(0 0 0 / 8%)
    &-picker
        &-dropdown
            .ant-picker-header
                border: none
                margin-top: rem(10)
        &-quarter-panel
            width: rem(200)

            .ant-picker-content
                tbody
                    td
                        display: block
                        text-align: left
                        padding: 0
                    .ant-picker-cell
                        &-inner
                            display: block
                            width: auto
                        .ant-picker-cell-inner
                            margin: 0 -0.5rem
                            padding: rem(6) rem(12)
                            height: auto
                            border-radius: 0
                        &-in-view.ant-picker-cell-selected
                            .ant-picker-cell-inner
                                background: #E0EEFF
                                color: $colors-dark
    &-radio
        &-inner

            &::after
                background: #fff
        &-checked
            .ant-radio-inner
                background: $colors-blue-400
            &::after
                border

        &-group-outline
            background: #FFFFFF
            /* Gray/$gray-100
            border: 1px solid $colors-gray-100
            border-radius: 6px
            padding: 0.25rem
            display: flex
            gap: 0.25rem

        &-button-wrapper
            border-radius: 4px !important
            color: #626B79
            height: rem(26)
            line-height: rem(26)
            border: none !important
            min-width: rem(82)
            text-align: center

            &-checked:not(.ant-radio-button-wrapper-disabled)
                background: #EAECFB
                &::before
                    background: none
                    display: none

            &:not(:first-child)::before
                display: none

/*Toastify */
.toast-icon
    overflow: hidden
    width: rem(24)
    height: rem(24)
    display: inline-block
    vertical-align: middle
    fill: none
    flex-shrink: 0
    backface-visibility: hidden
    margin-right: rem(8)

.Toastify
    --success-color: #3C914D
    --error-color: #b33029
    --info-color: #0031BE
    --warning-color: #DB8000
    &__toast
        box-shadow: 0 rem(10) rem(15) rem(-3) rgba(0, 0, 0, 0.1), 0 rem(4) rem(6) rem(-2) rgba(0, 0, 0, 0.05)
        border-radius: rem(6)
        border: 1.5px solid
        padding: 0 rem(12)
        min-height: rem(48)
        &-container
            display: flex
            flex-flow: column
            align-items: center
            width: auto
            &--top-right
                .Toastify__toast--warning
                    background: #fbf3de
                    border: none
                    .Toastify__toast-body
                        align-items: start
                        padding: 1rem 0
                        max-width: 300px
            .toast-title
                font-weight: bold
                margin-bottom: 10px
            .toast-action
                margin-top: 1rem
                display: flex
                gap: 12px

        &--error
            border-color: var(--error-color)
        &--success
            border-color: var(--success-color)
        &--info
            border-color: var(--info-color)
        &--warning
            border-color: var(--warning-color)
        &-body
            padding: 0
            &> div:last-child
                font-family: "Inter",sans-serif
                color: #232933

/* .custom
 &-table
 .ant-table-pagination.ant-pagination
 position: relative
 li
 &.ant-pagination-options
 position: absolute
 left: 0
 &.ant-pagination-options
 &:before
 content: "Rows per page "
 color: #626B79

 .ant-select:not(.ant-select-customize-input) .ant-select-selector
 border: none
 &.ant-pagination-total-text
 position: absolute
 left: 14.5rem
