.timeline-status
    p
        margin: 0
        color: #232933
    span
        color: #626B79
        font-size: rem(12)
    &.ant-timeline-reverse 
        .ant-timeline-item-last 
            .ant-timeline-item-tail
                display: block
                height: 50%

    .ant-timeline-item.ant-timeline-item-last
        padding: 0
        &> .ant-timeline-item-content
            min-height: 0
    .ant-timeline-item
        padding-bottom: 1rem
        &:last-child
            padding-bottom: 0
        &-head
            background: $colors-green-700
            border-color: $colors-green-700
        &.red
            .ant-timeline-item-head
                background: $colors-red-700
                border-color: $colors-red-700
        &.yellow
            .ant-timeline-item-head
                background: $colors-orange-700
                border-color: $colors-orange-700

.status-requisition
    .anticon
        position: relative
        top: 2px