$colors-transparent: transparent
$colors-current: currentColor
$colors-overlay: rgba(22, 26, 32, 0.8)
$colors-background: #F3F7FC
$colors-dark: #232933
$colors-black: #000
$colors-white: #fff
$colors-whiteAlpha-50: rgba(255, 255, 255, 0.04)
$colors-whiteAlpha-100: rgba(255, 255, 255, 0.06)
$colors-whiteAlpha-200: rgba(255, 255, 255, 0.08)
$colors-whiteAlpha-300: rgba(255, 255, 255, 0.16)
$colors-whiteAlpha-400: rgba(255, 255, 255, 0.24)
$colors-whiteAlpha-500: rgba(255, 255, 255, 0.36)
$colors-whiteAlpha-600: rgba(255, 255, 255, 0.48)
$colors-whiteAlpha-700: rgba(255, 255, 255, 0.64)
$colors-whiteAlpha-800: rgba(255, 255, 255, 0.8)
$colors-whiteAlpha-900: rgba(255, 255, 255, 0.92)
$colors-blackAlpha-50: rgba(0, 0, 0, 0.04)
$colors-blackAlpha-100: rgba(0, 0, 0, 0.06)
$colors-blackAlpha-200: rgba(0, 0, 0, 0.08)
$colors-blackAlpha-300: rgba(0, 0, 0, 0.16)
$colors-blackAlpha-400: rgba(0, 0, 0, 0.24)
$colors-blackAlpha-500: rgba(0, 0, 0, 0.36)
$colors-blackAlpha-600: rgba(0, 0, 0, 0.48)
$colors-blackAlpha-700: rgba(0, 0, 0, 0.64)
$colors-blackAlpha-800: rgba(0, 0, 0, 0.8)
$colors-blackAlpha-900: rgba(0, 0, 0, 0.92)
$colors-gray-25: #F8F9FB
$colors-gray-50: #EDF0F2
$colors-gray-100: #D0D6DD
$colors-gray-400: #8B95A5
$colors-gray-600: #626B79
$colors-blue-50: #EAECFB
$colors-blue-100: #D6DFFF
$colors-blue-400: #4B61DD
$colors-blue-500: #1C44D5
$colors-blue-700: #0031BE
$colors-blue-800: #0031BE
$colors-green-50: #E8F5EB
$colors-green-400: #68BE79
$colors-green-500: #4FB262
$colors-green-700: #3C914D
$colors-orange-50: #FBF3DD
$colors-orange-400: #E0AB00
$colors-orange-500: #DE9400
$colors-orange-700: #DB8000
$colors-red-50: #FDECEE
$colors-red-400: #DA473F
$colors-red-500: #C83E39
$colors-red-700: #AB3129
$colors-ice-50: #E0EEFF
$colors-ice-100: #B4D3F2
$colors-brand-mekari: #5B37D4
$colors-brand-jurnal: #009BDE
$colors-brand-talenta: #C02A34
$colors-brand-klikpajak: #F96D01
$colors-brand-chat: #00B894
$colors-brand-sign: #68BE79
$colors-brand-flex: #4B3F72
$borders-none: 0
$borders-half: 0.75px solid
$borders-1px: 1px solid
$borders-1-5px: 1.5px solid
$borders-2px: 2px solid
$borders-4px: 4px solid
$borderWidths-sm: 1px
$borderWidths-md: 2px
$borderWidths-lg: 4px
$fonts-body: "inter",-apple-system,BlinkMacSystemFont,Helvetica,Arial,sans-serif,"Apple Color Emoji"
$fonts-mono: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace
$fontSizes-xs: 0.625rem
$fontSizes-sm: 0.75rem
$fontSizes-md: 0.875rem
$fontSizes-lg: 1rem
$fontSizes-xl: 1.25rem
$fontSizes-2xl: 1.5rem
$fontWeights-regular: 400
$fontWeights-semibold: 600
$letterSpacings-tighter: -0.05em
$letterSpacings-tight: -0.025em
$letterSpacings-normal: 0
$letterSpacings-wide: 0.025em
$letterSpacings-wider: 0.05em
$letterSpacings-widest: 0.1em
$lineHeights-normal: normal
$lineHeights-none: 0
$lineHeights-2xs: 0.6
$lineHeights-xs: 0.75
$lineHeights-sm: 1
$lineHeights-1sm: 1.2
$lineHeights-md: 1.4
$lineHeights-xxlg: 1.34
$lineHeights-xlg: 1.3
$lineHeights-1lg: 1.6
$lineHeights-2lg: 1.7
$lineHeights-lg: 1.5
$lineHeights-3lg: 1.715
$lineHeights-xl: 2
$lineHeights-2xl: 2.25
$radii-none: 0
$radii-xs: 0.125rem
$radii-sm: 0.25rem
$radii-md: 0.375rem
$radii-full: 624.938rem
$space-0: 0
$space-1: 0.25rem
$space-2: 0.5rem
$space-3: 0.75rem
$space-4: 1rem
$space-5: 1.25rem
$space-6: 1.5rem
$space-7: 1.75rem
$space-8: 2rem
$space-9: 2.25rem
$space-10: 2.5rem
$space-11: 2.75rem
$space-12: 3rem
$space-13: 3.625rem
$space-16: 4rem
$space-20: 5rem
$space-24: 6rem
$space-32: 8rem
$space-40: 10rem
$space-48: 12rem
$space-56: 14rem
$space-64: 16rem
$space-65: 17.5rem
$space-0-5: 0.125rem
$space-1-5: 0.375rem
$space-2-5: 0.625rem
$space-3-2: 0.875rem
$space-3-5: 0.938rem
$space-7-5: 1.875rem
$space-9-5: 2.375rem
$space-px: 1px
$shadows-sm: 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)
$shadows-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)
$shadows-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)
$shadows-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04)
$shadows-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
$shadows-outline: 0 0 0 3px #E0EEFF
$shadows-outer: 0 0 0 3px rgba(224, 238, 255, 1)
$shadows-outer-invalid: 0 0 0 3px rgba(253, 236, 238, 1)
$shadows-inner: inset 0 2px 4px 0 rgba( 0, 0, 0, 0.06)
$shadows-none: none
$sizes-0: 0
$sizes-1: 0.25rem
$sizes-2: 0.5rem
$sizes-3: 0.75rem
$sizes-4: 1rem
$sizes-5: 1.25rem
$sizes-6: 1.5rem
$sizes-7: 1.75rem
$sizes-8: 2rem
$sizes-9: 2.25rem
$sizes-10: 2.5rem
$sizes-11: 2.75rem
$sizes-12: 3rem
$sizes-13: 3.625rem
$sizes-16: 4rem
$sizes-20: 5rem
$sizes-24: 6rem
$sizes-32: 8rem
$sizes-40: 10rem
$sizes-48: 12rem
$sizes-56: 14rem
$sizes-64: 16rem
$sizes-65: 17.5rem
$sizes-0-5: 0.125rem
$sizes-1-5: 0.375rem
$sizes-2-5: 0.625rem
$sizes-3-2: 0.875rem
$sizes-3-5: 0.938rem
$sizes-7-5: 1.875rem
$sizes-9-5: 2.375rem
$sizes-px: 1px
$sizes-full: 100%
$sizes-3xs: 14rem
$sizes-2xs: 16rem
$sizes-xs: 20rem
$sizes-sm: 24rem
$sizes-md: 28rem
$sizes-lg: 32rem
$sizes-xl: 36rem
$sizes-2xl: 42rem
$sizes-3xl: 48rem
$sizes-4xl: 56rem
$sizes-5xl: 64rem
$sizes-6xl: 72rem
$sizes-containers-sm: 640px
$sizes-containers-md: 768px
$sizes-containers-lg: 1024px
$sizes-containers-xl: 1280px
$zIndices-hide: -1
$zIndices-auto: auto
$zIndices-base: 0
$zIndices-docked: 10
$zIndices-dropdown: 1000
$zIndices-sticky: 1100
$zIndices-banner: 1200
$zIndices-overlay: 1300
$zIndices-modal: 1400
$zIndices-popover: 1500
$zIndices-skipLink: 1600
$zIndices-toast: 1700
$zIndices-tooltip: 1800
$transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s