/* Boostrap */
.container
  width: 100%
  padding-right: 1rem
  padding-left: 1rem
  margin-right: auto
  margin-left: auto


.pointer
  cursor: pointer


button
  &:hover,
  &:focus
    outline: none !important




body
  font-size: 16px
  line-height: rem(20)
  font-family: 'Inter', sans-serif
  font-weight: normal
  padding: 0
  margin: 0
  background-color: $colors-background
  color: $colors-dark
  text-rendering: optimizeLegibility
  -webkit-font-smoothing: antialiased
  #root
    font-size: rem(14)
  //overflow-x: hidden
html,body
  +responsive(0,3500px)
    font-size: 26px
  +responsive(3500px,2000px)
    font-size: 18px
  +responsive(1700px,1400px)
    font-size: 16px
  +responsive(1400px,1300px)
    font-size: 14px
  +responsive(1300px,1000px)
    font-size: 12px
  +responsive(1000px)
    font-size: 12px
    line-height: 1.5em

/* Width */
$w : 80 156 160 176 200 240 260 280 312 320 376 400 480 552 578 600 720 776
@for $i from 1 through length($w)
  .w-#{nth($w,$i)}
    width: rem(nth($w,$i))

.w-full
  width: 100%
  
.disabled
  pointer-events: none
  color: #999

.opacity-50
  opacity: 0.5

.block
  display: block
.inline-block
  display: inline-block

/* Margin & Padding */
$mg : 0 4 6 8 12 16 20 24 28 32 36 40 44 48 56 64
@for $i from 1 through length($mg)
  .mr-#{nth($mg, $i)}
    margin-right: rem(nth($mg, $i))
  .ml-#{nth($mg, $i)}
    margin-left: rem(nth($mg, $i))
  .mb-#{nth($mg, $i)}
    margin-bottom: rem(nth($mg, $i))
  .mt-#{nth($mg, $i)}
    margin-top: rem(nth($mg, $i))
  .mbt-#{nth($mg, $i)}
    margin-top: rem(nth($mg, $i))
    margin-bottom: rem(nth($mg, $i))
  .pbt-#{nth($mg, $i)}
    padding-top: rem(nth($mg, $i))
    padding-bottom: rem(nth($mg, $i))
  .pd-#{nth($mg, $i)}
    padding: rem(nth($mg, $i))
  
.m-0
  margin: 0 !important


/* flex & friends */
.flex
    display: flex
    &-start
      align-items: start
    &-end
      justify-content: flex-end
    &-between
      justify-content: space-between
      align-items: center
    &-between-top
      justify-content: space-between
      align-items: start
    &-center
      justify-content: center
      align-items: center
    &-aligns-center
      align-items: center
    &-bottom
      align-items: end
    &-wrap
      flex-wrap: wrap
    &-column
      flex-direction: column


.flexs
  &-auto
    flex: 1 1

/*gap */
$mg : 0 8 12 16 24 32
@for $i from 1 through length($mg)
  .g-#{nth($mg, $i)}
    gap: rem(nth($mg, $i))
  
[class*="flex-"]
  @extend .flex

.hidden
  display: none


ul,ol
  &.plain
      padding-left: 20px
    
section + section
  margin-top: 2rem

.error-page-message
  background: #eee
  padding: rem(16)
  color: #999
  border-radius: 6px
  max-height: rem(200)
  overflow: auto
