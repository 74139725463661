main:not(.ant-layout-content)
  margin-top: rem(57)

.sort-icon.anticon
  font-size: 1.25rem

.ant-btn
  &-hidden
    display: none
  &.block
    width: 100%
    +font-600()

.page-loading
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  .ant-spin .anticon
    font-size: rem(36) !important
  &.full
    height: 90vh

.btn-icon
  width: 2.25rem
  height: 2.25rem
  line-height: 2.25rem
  display: inline-block
  font-size: 1.5rem
  text-align: center
  border-radius: rem(4)
  color: $colors-gray-600
  &:hover
    background: #E0EEFF

.btn-copy
  opacity: 0.5
  &:hover
    opacity: 1

.ballon
  background: $colors-red-500
  border-radius: 10px
  color: #fff
  font-size: rem(12)
  line-height: 1rem
  padding: rem(2) rem(6)
  display: inline-block
  &--title
    background: $colors-blue-50
    color: $colors-blue-700

  /* Tag */
.tags
  .tag
    margin: rem(2)
.tag
  background: $colors-gray-50
  border-radius: 999px
  line-height: rem(28)
  border: none
  color: $colors-gray-600
  .anticon
    padding: 0
    padding-left: 4px
    font-size: 12px

.tag
  border-radius: rem(10)
  padding: 0 0.5rem
  display: inline-block
  &:not(:first-child)
    margin-left: 0.25rem
  &-primary
    background: $colors-blue-50
    color: $colors-blue-700

.ant-tag
  line-height: rem(20)
  padding: 0 rem(6)
  border-radius: rem(12)
  background: $colors-gray-50
  font-size: rem(14)
  &-yellow
    background: $colors-orange-50
    color: $colors-orange-700
  &-green
    background: $colors-green-50
    color: $colors-green-700
  &-blue
    background: $colors-blue-50
    color: $colors-blue-700

.badge
  background: #71717A
  color: #fff
  padding: 0 6px
  line-height: 20px
  border-radius: 12px

[class*="tag-"]
  @extend .tag

$val: 24 48 64
@for $i from 1 through length($val)
  .avatar--#{nth($val, $i)}
    width: rem(nth($val, $i))
    height: rem(nth($val, $i))
    line-height: rem(nth($val, $i))
    font-size: rem(nth($val, $i) * 0.4)

.nav
  &--switch
    padding: 4px 8px
    border-radius: 4px
    cursor: pointer
    &.active
      background: $colors-blue-50
      color: $colors-blue-400
    &-box
      display: flex
      flex-direction: row
      align-items: flex-start
      padding: 0.25rem
      gap: 0.25rem
      background: #FFF
      border: 1px solid $colors-gray-100
      border-radius: rem(6)

.under-cons
  flex: 1 0 0
