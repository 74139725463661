.data-list
    display: flex
    flex-wrap: wrap
    column-gap: 1.5rem
    align-items: flex-start
    dt:not(.text-semibold)
        font-weight: 400
    hr
        flex: 0 0 100%
        border: none
        border-top: 1px solid $colors-gray-50
        margin: rem(16) 0
    ul
        padding-left: rem(20)
    &:not(.all-black)
        dt
            color: $colors-gray-600
    .data-list-form
        flex: 0 0 100%
        display: flex
        gap: rem(12)
        .ant-form-item
            margin: 0
        &-action
            padding-top: rem(24)
            
            .ant-btn-icon
                margin-top: rem(2.5)

    &:not(.vertical)
        dt,dd
            &:not([class*="mb-"])
                margin: 0
        dt,dd
            padding: 0.5rem 0
            &.sub
                color: $colors-gray-600
                font-size: rem(12)
                padding: rem(4) 0
        dt
            flex: 0 0 rem(264)
            max-width: rem(264)
            &.sub
                padding-left: rem(24)
            
        dd
            flex: 0 0 calc(100% - rem(288))
            max-width: calc(100% - rem(288))
        &.percent
            dt
                flex: 0 0 22%
                max-width: 22%
                color: #6C757D
            dd
                flex: 0 0 74%
                max-width: 74%
        &.layout-160
            dt
                flex: 0 0 rem(160)
                max-width: rem(160)
            dd
                flex: 0 0 calc(100% - rem(184))
                max-width: calc(100% - rem(184))
        &.layout-64
            dt
                flex: 0 0 30%
                max-width: 30%
            dd
                flex: 0 0 50%
                max-width: 50%
        &.layout-50
            dt,dd
                flex: 0 0 calc(50% - 0.75rem)
                max-width: calc(50% - 0.75rem)

    &.align-center,&--center
        align-items: center
    &.pdmb-0
        margin: 0
        dt,dd
            padding: 0
    &.bt16
        padding: rem(16) 0
        border-bottom: 1px solid $colors-gray-50
    &.vertical
        dt,dd
            flex: 0 0 100%
        dt
            margin: 0 0 rem(4)
        dd
            margin: 0 0 rem(16)


