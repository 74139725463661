.collapse
    &--node-icon
        .toggle-icon
            transition: all .5s
        .ant-collapse-item
            &-active 
                .toggle-icon
                    transform: rotate(-180deg)
    &--sublevel
        color: $colors-gray-600
        .ant-collapse-content
            &-box
                padding: 0.25rem 0 rem(6) !important
                font-size: 0.75rem
                line-height: 1rem
        ul
            list-style-type: disc
            li
                font-size: 0.75rem
                line-height: 1rem
                &:not(:last-child)
                    margin-bottom: 0.25rem

.ant-collapse-ghost.ghost-collapse-tight 
    &> .ant-collapse-item 
        &> .ant-collapse-header
            //padding: 0 0 0.5rem 0
            padding: 0
        &> .ant-collapse-content 
            &> .ant-collapse-content-box
                padding-left: 0
                padding-right: 0