.table
    &-action
        text-align: right
        display: flex
        justify-content: flex-end
        gap: 1rem
        a:not(.ant-btn):not(.link)
            color: $colors-gray-600
            width: 2rem
            height: 2rem
            border-radius: 4px
            display: flex
            justify-content: center
            align-items: center
            &:hover
                background: $colors-ice-50
                color: $colors-blue-400

        .custom.anticon
            width: rem(36)
            height: rem(36)
            display: flex
            align-items: center
            justify-content: center
            border-radius: rem(6)
            margin: rem(-10) 0
            &:hover
                background: $colors-ice-50
        .ant-btn
            //margin: rem(-6) 0
    &-thead
        display: flex
        align-items: center
    &-search
        border-color: #D0D6DD
        padding: rem(4) rem(12)
        border-radius: rem(6)
        width: rem(280)
        span.ant-input-prefix
            color: $colors-gray-600
        .anticon,.ant-input
            position: relative
            top: -1px
        .ant-input
            height: 1.625rem
        .ant-input-prefix
            margin-right: rem(12)
    &-valign-top
        th,td
            vertical-align: top
    &-row
        &-gray
            color: $colors-gray-600
    &-cell
        &-action
            display: flex
            align-items: center
            justify-content: space-between
            gap: rem(8)
            text-decoration: underline
            &.gray
                color: $colors-gray-400
                
            &:not(:hover)
                .ant-btn-icon
                    opacity: 0
    &-footer
        font-weight: 600
        td
            background: $colors-gray-25
            line-height: rem(36)
.ant-table
    &-cell
        .ant-form-item
            margin: 0

.circle
    width: 0.75rem
    height: 0.75rem
    display: inline-block
    background: $colors-orange-400
    border-radius: 50%
    &--green
        background: $colors-green-400
    &--red
        background: $colors-red-400

.transfer-changed
    td
        background: #E0EEFF

.editable-table
    &.table-error
        .ant-table-row.editing
            td
                padding-bottom: rem(24)
        .ant-form-item-with-help

            .ant-form-item-explain
                margin-bottom: rem(-20)
    .ant-select-multiple .ant-select-selector::after
        display: none
