.sidebar,.sidebar-submenu
    position: relative
    height: calc(100vh - rem(57))
    z-index: 902
    transition: $transition
.sidebar
    z-index: 903
    width: rem(240)
    position: absolute
    top: 0
    left: 0
    overflow: hidden
    background: $colors-background
    &-main
        width: rem(225)
        height: calc(100% - rem(57))
        padding: 1rem 0.5rem
        overflow: auto
        .ant-divider
            margin: 1rem 0
            &:first-child,&:last-child
                display: none
    &-link
        text-decoration: none
        padding: rem(8) rem(10)
        align-items: center
        color: $colors-dark
        border-radius: $radii-md

        &:hover
            color: $colors-blue-400
        &.active
            background: $colors-blue-100
            color: $colors-blue-400
            font-weight: 600
    &-label
        transition: all .5s ease .3s
        overflow: hidden
        white-space: nowrap
    &-footer,&-submenu-footer
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        border-top: 1px solid $colors-gray-100
        cursor: pointer
    &-footer
        &-text
            padding: rem(8) rem(10)
            align-items: center
        &-btn
            line-height: 1
            padding: rem(8)
            border-radius: $radii-md
            cursor: pointer
            transition: all .5s ease
            &:hover
                background: $colors-blue-100
            .anticon
                //font-size: rem(20)
    &.with-submenu
        background: #E7EDF5
        border-right: 1px solid #D0D6DD
    &.collapsed
        width: rem(60)
        .sidebar
            &-main
                width: auto
                &-label
                    max-width: 0
                    opacity: 0
        &:not(.none):hover
            width: rem(216)
            box-shadow: $shadows-lg
            .sidebar
                &-main
                    &-label
                        opacity: 1
                        max-width: 100vw
        &+ .sidebar-submenu,&+ .main-page
            margin-left: rem(61)

    &-submenu
        margin-left: rem(226)
        //min-width: rem(216)
        width: rem(216)
        background: $colors-background
        //overflow: hidden
        &-wrapper
            padding: 1rem 0
            overflow: hidden
            height: calc(100vh - rem(57))
            transition: $transition
            width: 100%
        h3
            font-weight: 600
            font-size: rem(12)
            line-height: rem(16)
            letter-spacing: 2px
            font-feature-settings: 'tnum' on, 'lnum' on
            color: $colors-blue-400
            text-transform: uppercase
            margin: rem(16) 0 0 0
            padding: rem(8)
            &:first-child
                margin-top: 0
        .sidebar-link
            display: block
            transition: $transition
        h3,.sidebar-link
            white-space: nowrap
            overflow: hidden
            transition: all .5s ease .3s
        &-nav
            height: calc(100% - rem(43))
            overflow: auto
            padding: 0 rem(8) rem(24)
            &::-webkit-scrollbar
                width: rem(6)
                height: rem(6)
                background-color: $colors-background
            &::-webkit-scrollbar-thumb
                background-color: rgba(#000,.2)
                border-radius: 10px
                border: 1px solid rgba($colors-gray-400,.5)
        &-main
            height: 100%
            width: 100%
            transition: $transition
            overflow: hidden
        &-footer
            padding: rem(8) rem(12)
            border: none
            background: $colors-background
            transition: $transition
            &-btn
                padding: rem(8)
                cursor: pointer
                &:hover
            &-collapse
                position: absolute
                left: rem(-15)
                display: flex
                bottom: rem(8)
                cursor: pointer
                opacity: 0
                visibility: hidden
                width: rem(24)
                height: rem(36)
                background-color: $colors-white
                border: $borders-1px
                border-color: $colors-gray-100
                border-top-right-radius: $radii-full
                border-bottom-right-radius: $radii-full
                padding-right: $space-1
                padding-left: $space-1
                box-shadow: rgb(0 0 0 / 14%) 3px 0px 4px, rgb(0 0 0 / 12%) 0px 0px 2px
                transition: all .3s cubic-bezier(.4,0,.2,1)

                color: $colors-gray-600
        &.collapsed
            width: rem(32)
            //min-width: rem(32)
            padding-right: rem(16)
            //padding-left: 0
            overflow: visible
            .sidebar-submenu
                &-wrapper
                    width: rem(16)
                    &:hover
                        background: #E7EDF5
                        .sidebar-submenu-footer
                            background: #E7EDF5
                            &-collapse
                                padding-left: rem(12)
                                width: rem(36)
                &-wrapper,&-footer
                    border-right: 1px solid #D0D6DD
                &-footer
                    width: 0
                    padding: 0 0 0 rem(16)
                    //left: rem(-15)
                    color: #fff
                    .sidebar-footer-btn
                        pointer-events: none
                        visibility: hidden
                    &-collapse
                        visibility: visible
                        opacity: 1
                        left: rem(15)
                        transition-delay: 0.3s
                        &:hover
                            padding-left: rem(12)
                            width: rem(36)
                &-nav
                    padding-right: rem(16)
                    overflow: hidden
                &-main
                    width: 0
                    padding: 0
            h3,.sidebar-link
                //overflow: hidden
                //width: 0

                //opacity: 0

    & + .main-page
        margin-left: rem(240)
        transition: $transition
