.mulfil
    flex: 0 0 calc(100% - rem(300))
    align-items: center
    max-width: calc(100% - rem(300))
    flex-wrap: wrap
    &.shorter
        flex: 0 0 calc(100% - rem(420))
        max-width: calc(100% - rem(420))
    &-add,&-field
        height: rem(36)
        padding: 0 rem(24) 0 rem(20)
        border-radius: rem(100)
        border: 1px dashed #8B95A5
        cursor: pointer
    &-add
        transition: $transition
        &:hover
            border-color: #B4D3F2
            color: $colors-blue-400
            background:  #E0EEFF
        &-option
            .ant-dropdown-menu-title-content
                display: flex
                justify-content: space-between
                align-items: center
                gap: rem(24)
            &:not(.ant-dropdown-menu-item-selected)
                .anticon
                    opacity: 0
                
    &-field
        overflow: hidden
        padding-right: 0
        border-style: solid
        &::before
            content: attr(data-label)" :"
            flex: 1 0 auto
            pointer-events: none
            display: none
        &-label
            flex: 1 0 auto
        &.filled
            border-color: #B4D3F2
            color: $colors-blue-400
            background:  #E0EEFF
            &::before
                display: flex
            .ant-picker-clear,.ant-select-clear
                background:  #E0EEFF
                color: $colors-blue-400
        .ant-select:not(.ant-select-customize-input)
            max-width: rem(160) !important
            transform: translateY(rem(1))
            .txt-small
                display: none
        .ant-select,.ant-select-arrow,.ant-picker-input > input,.ant-picker-suffix
            color: inherit
            
        .ant-select:not(.ant-select-customize-input) .ant-select-selector,
        .ant-picker
                padding-left: 0
                border: none
                box-shadow: none  !important
                background: none
                color: inherit
        .ant-picker
            width: rem(100)
            
