h1
  font-weight: 600
  font-size: rem(24)
  line-height: rem(32)
  letter-spacing: -0.02em
h3
  font-weight: 600
  font-size: rem(20)
  line-height: rem(32)

h4
  font-weight: 600
  font-size: 1rem
  line-height: 1.5

h5
  font-size: rem(12)
  line-height: rem(16)
  margin: 0


small
  font-size: rem(12)
  line-height: 1rem

p,ul,ol
  &:last-child
    margin: 0

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section,img
  display: block

a,.link
  color: $colors-blue-400
  transition: color .3s ease-in-out
  cursor: pointer       
  &:hover
    color: $colors-blue-400
    text-decoration: none
a.line,a
  text-decoration: underline
  //display: inline-block
a.ant-btn
  text-decoration: none
ul.tight-left-list
  padding-left: rem(20)

.link
  &--ghost
    color: currentColor !important
    text-decoration: none !important
  &--nu
    text-decoration: none
  



.text
    &-20
      font-size: rem(20) !important
    &-left
        text-align: left
    &-center
        text-align: center
    &-right
        text-align: right
    &-ellipsis
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      width: 100%
      display: block
    
.txt,.text
  &-normal
    font-size: rem(14)
    line-height: 1.5
  &-small
      font-size: rem(12)
      color: $colors-gray-600
      line-height: 1rem
  &-gray
      color: $colors-gray-400
      &+ .txt
        &-small
          color: $colors-gray-400
  &-semibold
    font-weight: 600
  
  
a.txt,a.text
  &-small
    color: $colors-blue-400