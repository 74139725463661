.modal-confirm
    border-radius: 6px
    overflow: hidden
    border: 1px solid #8B95A5
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)
    .ant-modal-body
        padding: 0

    .ant-modal-confirm-title
        padding: 1rem
        background: #F2F4F7
        box-shadow: 0px 1px 0px #D0D6DD

    .ant-modal-confirm-content
        padding: 1.5rem 1rem 1rem

    .ant-modal-confirm-btns
        padding: 0 1rem 1rem

.modal-delete .ant-modal-confirm-body>.anticon
    color: $colors-red-400

.modal-rows
  width: 330px
  border: 1px solid #8B95A5
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)
  border-radius: 6px
  overflow: hidden

  .ant-modal-header
    border-bottom: 1px solid #D0D6DD
  .ant-modal-body
    padding: 1rem
.data-row
  padding: 14px 1rem

  &:not(:last-child)
    border-bottom: 1px solid #D0D6DD 

.modalpreview
  &-pdf
    min-width: 1000px
  .ant-modal-body
    padding: 0
    position: relative
  .ant-modal-header
    background: #fff
  &-title
    margin: -0.5rem 0
    span.anticon
      font-size: 2rem
  &-loading
    width: 100%
    height: 40vh
    display: flex
    align-items: center
    justify-content: center
  &-viewer
    text-align: center
    background: #eee
    overflow: hidden
    border: 1px solid #ccc
    background: #f0f2f5
    &-canvas
      height: 70vh
      overflow: auto
    &-action
      background: #fff
      //background: rgba(0, 0, 0, 0.05)
      padding: 0.625rem 0
      border-top: 1px solid #ccc
      box-shadow: 0px 0 21px 0px rgba(0, 0, 0, 0.2)
      z-index: 3
      position: relative
      font-size: 0.875rem
      &-col
       gap: 0.25rem
      .ant-btn
        padding: 0 0.25rem
        font-size: 1.125rem
        &:not(:disabled)
          color: #595959
  &-float
    position: absolute
    top: 1.5rem
    right: 1.5rem
    &-btn
      //max-width: 2rem
      width: 2.5rem
      height: 2rem
      overflow: hidden
      //line-height: 2rem
      text-align: center
      background: rgba(0,0,0,0.5)
      border-radius: 6px
      color: #fff
      cursor: pointer
      transition: all .5s
      position: relative
      b,span
        position: absolute
        top: 0
        height: 100%
        line-height: 2rem
      span
        left: rem(12)
        top: rem(2)
      b
        font-weight: 400
        font-size: rem(12)
        left: rem(40)
      &:hover
        background: #000
        width: rem(110)
      &-loading
        width: rem(140)
        pointer-events: none
        opacity: 0.5
  &-img
    &-wrapper
      padding: 0.75rem
      position: relative
      background: #eee

      &:before
        content: ""
        display: block
        padding: 50% 0
    &-file
      position: absolute
      top: 10px
      left: 10px
      width: calc(100% - 20px)
      height: calc(100% - 20px)
      object-fit: contain
        


