.nested-filter
    width: rem(240)
    line-height: rem(36)
    border: 1px solid $colors-gray-100
    border-radius: 6px
    background: #fff
    padding: 0 rem(12)
    cursor: pointer
    position: relative
    transition: all .5s
    &-loading
        pointer-events: none
        cursor: not-allowed
        background: #eee
        opacity: 0.5
    &-overlay
        position: absolute
        left: 0
        top: calc(100% + rem(4))
        z-index: 5
        background: #fff
        border: 1px solid $colors-gray-100
        border-radius: 6px
        width: 100%
        padding: rem(8) 0
        transform: translateY(#{rem(-20)})
        opacity: 0
        visibility: hidden
        transition: all .5s
    &.open
        border-color: #758beb
        box-shadow: 0 0 0 0.125rem rgba(75, 97, 221, 0.2)
        .nested-filter-overlay
            opacity: 1
            visibility: visible
            transform: none
    &-list
        padding: rem(8) rem(12)
        cursor: pointer
        line-height: rem(20)
        position: relative
        &:hover
            background: #f9f9f9
        &.open
            background: $colors-gray-50
            .nested-filter-wrap
                opacity: 1
                visibility: visible
                transform: none
    &-wrap
        position: absolute
        top: 0
        left: 100%
        padding-left: rem(8)
        width: 100%
        min-width: rem(268)
        transform: translateX(#{rem(-20)})
        opacity: 0
        visibility: hidden
        transition: all .5s
        &-box
            background: #fff
            border: 1px solid $colors-gray-100
            border-radius: 6px
        .ant-checkbox-wrapper
            display: flex
            margin: 0
            padding: rem(8) 0
    &-search
        padding: rem(12) rem(12) rem(8)
    &-items
        padding: 0 rem(12) rem(12)
        max-height: rem(240)
        overflow: auto
    &-badge
        background: $colors-blue-400
        color: #fff
        padding: 0 rem(6)
        border-radius: rem(12)