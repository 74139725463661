.member-page
    min-width: 1200px

body
    overflow: hidden

main
    display: flex
    position: relative
    overflow: hidden
    height: calc(100vh - rem(57))
.main
    margin-left: 14.55rem
    &-page
        flex: 1 0 0
        max-height: calc(100vh - rem(57))
        overflow: auto
    &-title
        padding: 0 1.5rem
        height: rem(72)
        display: flex
        align-items: center
        h5
            color: #626B79
        h4,h1
            margin: 0
        .link-back
            font-size: 0.75rem
            line-height: 1rem
            color: $colors-blue-400

    &-content
        background: #fff
        
        min-height: calc(100vh - rem(129))
        border-radius: $radii-md
        border: solid $colors-gray-100
        border-width: 1px 0 0 1px
        overflow: hidden
        &-wrap
            min-height: calc(100vh - rem(130))
        &-content
            flex: 1 0 auto
            padding: 1.5rem
            max-width: 100%
        &-left
            flex: 0 0 rem(265)
            border-right: 1px solid $colors-gray-50
            background: #FBFCFD
            padding: 1.5rem
            &+ .main-content-content
                max-width: calc(100% - rem(250))
        &--with-tab
            min-height: calc(100vh - rem(175))
        &--nplr
            padding: 0

.title
    &-set
        margin: 0 0 1.5rem
        h3,p
            margin: 0
        p
            color: #626B79

.divider-data
    margin: 1.5rem 0
    border-top: 1px solid $colors-gray-100

.content-sticky-bottom
    padding-bottom: rem(76)
    .form-footer
        position: fixed
        bottom: 0
        left: 0
        //left: 0
        background: #fff
        padding: rem(16) 1.5rem
        z-index: 900
        border-top: 1px solid $colors-gray-100
article
    &.mb8
        &>p,&>ol,&>ul
            &:not(:last-child)
                margin-bottom: rem(8)