/* Header */
.header
  position: fixed
  width: 100%
  top: 0
  left: 0
  background: #fff
  padding: 0.5rem
  border-bottom: 1px solid #D0D6DD
  z-index: 1001
  &-logo
    img
      max-height: rem(40)
  &-switch
    .ant-select
      width: rem(188)
  &-divider
    margin: 0 16px
    display: inline-flex
    width: 1px
    height: 24px
    background: #D0D6DD
  &-icons,&-user
    @extend .flex-aligns-center
  &-icons
    a
      margin: 0 0.5rem
  &-icon
    width: 1.5rem
  &-user
    &-avatar
      margin-right: 0.5rem
    b
      display: block


  