.tab-page
    .ballon
        margin-left: 0.5rem
    .ant-tabs
        &-nav
            margin: 0
            padding: 0 1.5rem
        &-tab
            &:not(.ant-tabs-tab-active)
                .ballon
                    background: $colors-gray-50
                    color: $colors-dark

.menu-top
    margin-left: rem(12)
    .ballon
        margin-left: 0.5rem
    &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
    &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu
        padding: 0 rem(12)
        &::after
            left: rem(12)
            right: rem(12)
    a
        display: block
    .num
        font-weight: 600
        color: #232933
    .ant-menu
        &-item:not(.ant-menu-item-selected)
            .ballon
                background: #EDF0F2
                color: $colors-dark
        &-item.ant-menu-item-selected
            .ballon
                background: $colors-blue-50
                color: $colors-blue-700

.breadcrumb

    line-height: 1.2
    margin: 0 0 2px
    display: inline-block
    color: $colors-gray-600
    a,a.link-back
        color: $colors-blue-400
        text-decoration: underline
        &:hover
            color: $colors-gray-600
    span
        &:not(:last-child):after
            content: "/"
            display: inline-block
            margin: 0 0.25rem

.ant-tabs-tab
    padding: rem(14) 0
    border-bottom: 2px solid #fff
    transform: translateY(-1px)
    &.ant-tabs-tab-active
        border-color: $colors-blue-400
    &+ .ant-tabs-tab
        margin-left: rem(24)
