.without-label
    .ant-form-item-label
        display: none
.without-msg
    .ant-form-item-explain-error
        display: none
    .ant-form-item-with-help .ant-form-item-explain
        min-height: 0
.filter-select
    .ant-select
        .ant-select-selector
            display: flex
            align-items: center
            padding: rem(4) rem(12)
            background: #D0D6DD !important
            border-radius: rem(24)
            &::before
                content: var(--title) ": "
        &-arrow
            display: none
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder
        padding: 0
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector
        border: none
        box-shadow: none

.filters
    .filter-select + .filter-select
        margin-left: 0.5rem
    
.form
    &-medium
        width: 34.5rem
    &-large
        width: 46.5rem
    &-footer
        text-align: right
        .ant-btn + .ant-btn
            margin-left: 0.5rem
    
    
    &-repeater
        &-row
            .ant-row
                position: relative
                margin: 0 0 1.5rem
            .ant-form-item
                margin: 0
        &-delete
            //margin: 1.875rem 0 0 0.5rem
            display: flex
            align-self: end
            justify-content: center
            align-items: center
            margin-right: 0.5rem
    &--horizontal,&--inline
        .ant-form-item-label
            text-align: left
            & > label
                font-weight: 400
                color: #626B79
                &::after
                    display: none
    &--inline
        .ant-form-item-label
            flex: 0 0 rem(194)
            max-width: rem(194)
            word-break: break-word
            white-space: break-spaces
        .ant-form-item:not(.auto-width)
            .ant-form-item-control
                flex: 0 0 rem(312)
                max-width: rem(312)
                padding-left: 1.5rem
                //margin-left: 1.5rem
        .ant-form-item
            margin: 0 0 0.5rem
    &-repeat
        &-num-vertical
            .ant-input-number
                width: 100%
                &-handler-wrap
                    display: none
            .ant-form-item
                margin: 0
            .form
                &-row
                    display: flex
                    align-items: center
                    gap: rem(8)
                   
                &-row
                    flex-wrap: wrap
                    padding-bottom: rem(20)
                &-title
                    padding-bottom: rem(8)
                    padding-left: rem(30)
                    padding-right: rem(20)
                    font-weight: 600
                    
                &-col
                    flex: 1 0 0%
                    max-width: 100%
                    

                &-num
                    flex: 0 0 rem(20)
                    line-height: rem(20)
                    border-radius: 50%
                    background: $colors-blue-50
                    color: $colors-blue-700
                    text-align: center
    &-terms
        border-top: 1px solid $colors-gray-100
        padding: rem(8)
        color: $colors-gray-600
        font-size: rem(12)
        line-height: 133.333%
        ul
            padding-left: rem(20)
            li
                &:not(:last-child)
                    margin-bottom: rem(8)



            
            

            
.tbl-checkbox
    @extend .mb-24 
    &-head
        font-weight: 600
        box-shadow: inset 0px -1px 0px #D0D6DD
    &-body.reverse
        .ant-checkbox-group
            flex-direction: row-reverse
    .ant
        &-col
            padding: 0.75rem 0
        &-checkbox-group 
            display: flex
            .ant-checkbox-wrapper 
                flex: 1 1
        &-checkbox-wrapper-checked + .ant-checkbox-group-item:not(.ant-checkbox-wrapper-disabled)
            cursor: not-allowed
            pointer-events: none
            span
                //color: rgba(0,0,0,.25)
                //cursor: not-allowed
            .ant-checkbox-inner
                background-color: #8B95A5
                border-color: #8B95A5 !important
                &::after
                    border-color: #fff
                    -webkit-animation-name: none
                    animation-name: none
        &-collapse 
            background: #fff
            border: none
            &> .ant-collapse-item > .ant-collapse-header
                font-weight: 600
                padding: 1rem 0
                display: flex
                align-items: center    
                &> div
                    line-height: 1
            &-content 
                border: none
                &> .ant-collapse-content-box
                    padding: 0 1.5rem

.select-filter
    //padding: 0.75rem
    &-selectall
        padding: rem(8) rem(12)
    &-input
        padding: rem(12) rem(12) rem(8)
        .anticon
            font-size: rem(20)
    .ant-select-item-group
        line-height: rem(16)
        padding: rem(16) rem(12) rem(4)

.branch-select
    .ant-select-selector
        display: flex
        gap: rem(8)

    .ant-tag
        display: flex
        align-items: center
        margin: 0
        &-close-icon
            line-height: 1
    
    &.selected-all
        .select-all-tag
            padding: 0 rem(8)
        .ant-select-selection-overflow
            .ant-select-selection-overflow-item:not(:first-child)
                display: none



.checkbox-vertical 
    .ant-checkbox-wrapper
        display: flex
        padding: 0.5rem 0

.btn-dropdown
    padding: 0 rem(10)
    display: inline-flex
    align-items: center
    &.border-less
        border: none

.req-add
    &-body
        overflow: auto
        margin-bottom: 1.5rem
    &-items
        display: flex
        gap: 1.5rem
        padding-bottom: 1.5rem
        
    &-item
        position: relative
        flex: 0 0 25rem
        padding: 1.5rem
        //background: #F2F4F7
        border: 1px solid #D0D6DD
        border-radius: 8px
        overflow: hidden
        &:hover
            border: 1px solid #4B61DD
            box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)

    &-delete
        position: absolute
        top: 0
        right: 0
    &-add
        border: 1px solid #EAECFB
        border-radius: rem(6)
        //align-self: start
        padding: rem(8) rem(16) rem(8) rem(12)
        min-width: rem(400)
        justify-content: center
        font-weight: 600
        display: inline-flex
        &:hover
            text-decoration: none
            box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)
        &.disabled
            opacity: 0.5

.suffix-text,.prefix-text
  background: #EDF0F2
  border-radius: 0 rem(4) rem(4) 0
  margin: rem(-4) rem(-9) rem(-4) 0
  height: calc(100% + rem(8))
  line-height: rem(34)
  padding: 0 rem(6)
  font-weight: 600

.prefix-text
  margin: rem(-4) rem(12) rem(-4) rem(-9)
  line-height: 2rem
  border-radius: rem(4) 0  0 rem(4)

.ant-input-number-affix-wrapper
    .prefix,.suffix
        &-text
            margin-top: 0
            margin-bottom: 0
            height: auto


.ant-select-multiple
    .ant-avatar
        display: none

.recruiter-form
    margin: -1.5rem
    .ant-form-item-label
        flex: 0 0 10rem
        max-width: 10rem
    .ant-form-item-control
        flex: 0 0 21rem
        max-width: 21rem
        padding: 0
    &-item
        padding: 1.25rem 1.5rem 2rem
        position: relative
        &:not(:last-child)
            box-shadow: inset 0px -1px 0px #D0D6DD

    
    

    .ant-row.ant-form-item
        column-gap: 1.5rem
        justify-content: space-between
        &:not(:last-child)
            margin: 0 0 1.5rem

    &-delete, &-add
        cursor: pointer
        display: flex
        align-items: center
        gap: 0.625rem
    &-delete
        color: $colors-red-500
        position: absolute
        bottom: 1.5rem
        left: 1.5rem
    &-footer
        justify-content: space-between
        padding: 1rem 1.5rem
        align-items: center
        border-top: 1px solid #D0D6DD

    &-add
        
        color: $colors-blue-400
    &-body    
        overflow: auto
        max-height: calc(100vh - 8rem)
        scroll-behavior: smooth
.dragger-xls.ant-upload.ant-upload-drag 
    .ant-upload
        padding: 2.5rem 0
    &.loading
        opacity: 0.5
    &.full
        max-width: rem(900)
    .ant-upload-drag-icon
        .ant-pixel-icon
            font-size: 8rem
            margin: 0 0 1rem
            

.assign-row
    padding: 1rem 0 0.25rem
    gap: 1.5rem
    border-bottom: 1px solid #D0D6DD
    h4
        margin: 0 0 0.25rem
        font-size: 1rem
        line-height: 1.5
        font-weight: 600
    .ant-form-item-label > label
        min-height: 0 

.ant-select-dropdown
    &.w-600
        //min-width: rem(600) !important
        width: auto !important
.ant-select.select-title
    .ant-select-selection-item 
        font-weight: 600
        font-size: 1.5rem
        line-height: 2rem
        letter-spacing: -0.02em

    .ant-select-selector 
        padding-left: 0

span.attachment-upload

.attachment-upload-field
  padding: 4px 12px 4px 4px
  gap: 12px
  display: flex
  align-items: center
  border: 1px solid #D0D6DD
  border-radius: 6px
  margin: 0 0 4px
  color: #8B95A5

.attachment-item
  display: flex
  gap: 12px
  flex-direction: row
  padding: 0.5rem 0

  .anticon
    font-size: 1.5rem

.attachment-name div
  color: #4B61DD

.attachment-delete .anticon
  font-size: 1.25rem
  cursor: pointer

.attachment-name
  flex: 0 0 calc(100% - 70px)


.mentionfield
    &__input
        min-height: 2.125rem
        margin: 0
        padding: 0.375rem 0.688rem
        overflow-x: hidden
        overflow-y: auto
        vertical-align: top
        word-wrap: break-word
        border: 0.063rem solid #d9d9d9
        border-radius: 0.375rem
        transition: all 0.3s ease
        &::placeholder,&::-webkit-input-placeholder
            opacity: 0.5
        &:focus
            border-color: #758beb
            box-shadow: 0 0 0 0.125rem rgb(75 97 221 / 20%)
            border-right-width: 0.063rem !important
            outline: 0
       
    &__suggestions
        border: 1px solid #8B95A5
        box-shadow: 0 0.625rem 0.9375rem -0.1875rem rgb(0 0 0 / 10%), 0 0.25rem 0.375rem -0.125rem rgb(0 0 0 / 5%)
        border-radius: 0.375rem
        padding: 0.25rem 0
        max-height: rem(235)
        overflow: auto
        min-width: rem(221) !important
        transform: translateY(rem(14))
        z-index: 1001 !important
        &::-webkit-scrollbar 
            width: 8px
        &::-webkit-scrollbar-track 
            background: transparent
        &::-webkit-scrollbar-thumb 
            background: #7a7a7a
            border-radius: 10px
        &::-webkit-scrollbar-thumb:hover 
            background: #555
        
        &__item
            position: relative
            display: block
            min-height: 2rem
            padding: 0.313rem 0.75rem
            color: rgba(0, 0, 0, 0.85)
            font-weight: normal
            font-size: 0.875rem
            line-height: 1.375rem
            cursor: pointer
            transition: background 0.3s ease
            &--focused
                background: #F5F5F3

            &__highlight
                font-weight: 400
