.stats
    row-gap: rem(8)
    &-item
        flex: 1 0 auto
        //max-width: rem(395)
        min-width: rem(200)
        padding: rem(16)
        border-radius: 6px
        border: 1px solid $colors-gray-100
        h2
            margin: 0
            line-height: 1.6
        p
            color: $colors-gray-600
    &-info
        flex: 0 0 100%
        text-align: right
        color: $colors-gray-600
